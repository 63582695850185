import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardActionArea
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Tilt from "react-tilt";
import { isBrowser } from "react-device-detect";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: "flex-end"
  },
  card: {
    background: "transparent",
    borderRadius: 0,
    border: "2px solid #ffffff",
    boxShadow: "0 0 0 2px rgb(0, 0, 0)",
    padding: 4
    // margin: -15
  },
  cardContent: {
    padding: 12,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 0
  }
}));

const BlogPost = ({
  parallax,
  featuredImage,
  title,
  postDate,
  excerpt,
  url
}) => {
  const classes = useStyles();

  const BlogCard = (
    <Card classes={{ root: classes.card }}>
      <CardActionArea component={Link} to={url}>
        <Img fluid={featuredImage.childImageSharp.fluid} />
        <CardContent classes={{ root: classes.cardContent }}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
            style={{
              textTransform: "uppercase",
              marginBottom: 0,
              fontWeight: 600,
              fontFamily:
                "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif",
              lineHeight: 1.25
            }}
          >
            {title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {moment(postDate).format("LL")}
          </Typography>
          <Box marginY={1.25}>
            <Divider style={{ backgroundColor: "white" }} />
          </Box>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            component="p"
            style={{
              fontFamily:
                "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif"
            }}
          >
            {excerpt}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions classes={{ root: classes.cardActions }}>
        <Button
          fullWidth
          component={Link}
          to={url}
          variant="outlined"
          color="primary"
        >
          Read More
        </Button>
      </CardActions> */}
    </Card>
  );

  if (isBrowser && parallax) {
    return (
      <Tilt className="Tilt" options={{ max: 25, scale: 1 }}>
        {BlogCard}
      </Tilt>
    );
  }
  return BlogCard;
};

BlogPost.defaultProps = {
  parallax: true
};

BlogPost.propTypes = {
  parallax: PropTypes.bool
};

export default BlogPost;
